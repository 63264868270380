/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$background-color: #2e2d2d;

.ion-page {
  display: block !important;
  background-color: $background-color;
  overflow-y: scroll;
}

.pull-right {
  float: right;
}

.form-control {
  margin-bottom: 7px;
}

.hr-menu-toggle{
  height: 3px !important;
}

:root {
  /* These examples use the same color: sienna. */
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 160, 82, 45;

  /* These examples use the same color: lightsteelblue. */
  --ion-background-color: #000000;
  --ion-background-color-rgb: 176, 196, 222;
}

.wrapper {
  display: inline-block;
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 0px;
  width: 100%;
}

.btn-action-list {
  width: 100%;
}

.div-btn-create {
  padding: 0px;
  margin-top: 15px !important;
}

.custom-alert .alert-wrapper {
  background: #e5e5e5;
  background-color: white;
}

.custom-alert .alert-head {
  background-color: #e4e4e4;
  color: white;
}

.custom-alert .alert-head h2 {
  color: #868686;
}

.custom-alert .alert-wrapper .alert-message {
  padding-top: 10px !important;
  color: black;
}

.custom-alert .alert-button {
  color: #000;
}

.custom-alert {
  --backdrop-opacity: 0.2;
  --background: rgb(212, 62, 62);
}

.custom-alert-button {
  color: white;
}

ion-spinner {
  color: #7fff00;
  width: 100%;
  margin: 10px;
}

div.monthview-container table {
  background-color: #697a70 !important;
  color: white;
}

div.days button.attended {
  background-color: #06bb1d !important;
}

div.days button.no-attended {
  background-color: #eb0a0a !important;
}

div.days button.attended p {
  color: white !important;
}


div.form-group label{
  color: #dadada;
}

.pull-rigth{
  float: right;
}

.my-custom-modal-css {
  height: 53%;
  top: 30%;
  position: absolute; 
  display: flex;  
}

.my-custom-modal-calendar-filter {
  height: 74%;
  top: 20%;
  position: absolute;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.setting-modal {  // Modal cssClass class name
  ion-backdrop {
    --backdrop-opacity: 0;
  }
}


// .my-custom-select-class .select-interface-option{
//  background-color: yellowgreen;
// color: red;
// }


.my-custom-select-class .alert-radio-label{
 color: white;
 }

 
 .my-custom-select-class .alert-button-inner{
  color: white;
  }

  [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
    color:  #008b24;
    font-weight: bolder;
  } 

  [aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
    border-color: #008b24;
}

[aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: #008b24;
}

[aria-checked=true].sc-ion-alert-md .alert-radio-inner.sc-ion-alert-md {
  background-color: #008b24;
}



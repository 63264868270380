/*Unseen Column*/
@media only screen and (max-width: 800px) {
    #unseen table td:nth-child(2),
    #unseen table th:nth-child(2) {
      display: none;
    }
  }
  
  @media only screen and (max-width: 640px) {
    #unseen table td:nth-child(4),
    #unseen table th:nth-child(4),
    #unseen table td:nth-child(7),
    #unseen table th:nth-child(7),
    #unseen table td:nth-child(8),
    #unseen table th:nth-child(8) {
      display: none;
    }
  }
  
  /*flip-scroll*/
  
  @media only screen and (max-width: 800px) {
    #flip-scroll .cf:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
  
    #flip-scroll * html .cf {
      zoom: 1;
    }
  
    #flip-scroll *:first-child + html .cf {
      zoom: 1;
    }
  
    #flip-scroll table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }
  
    #flip-scroll th,
    #flip-scroll td {
      margin: 0;
      vertical-align: top;
    }
  
    #flip-scroll th {
      text-align: left;
    }
  
    #flip-scroll table {
      display: block;
      position: relative;
      width: 100%;
    }
  
    #flip-scroll thead {
      display: block;
      float: left;
    }
  
    #flip-scroll tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    #flip-scroll thead tr {
      display: block;
    }
  
    #flip-scroll th {
      display: block;
      text-align: right;
    }
  
    #flip-scroll tbody tr {
      display: inline-block;
      vertical-align: top;
    }
  
    #flip-scroll td {
      display: block;
      min-height: 1.25em;
      text-align: left;
    }
  
  
    /* sort out borders */
  
    #flip-scroll th {
      border-bottom: 0;
      border-left: 0;
    }
  
    #flip-scroll td {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
  
    #flip-scroll tbody tr {
      border-left: 1px solid #babcbf;
    }
  
    #flip-scroll th:last-child,
    #flip-scroll td:last-child {
      border-bottom: 1px solid #babcbf;
    }
  }
  
  /*no more table*/
  
  @media only screen and (max-width: 800px) {
  
    #no-more-tables tbody{
     border-top: none;
    }
  
    #no-more-tables tr.total > td.initial-cash {
      width: 100% !important;
    }
  
    #no-more-tables tr.total > td.end-cash {
      width: 100% !important;
    }
  
    .total-responsive {
      display: normal;
    }
  
    .total-non-responsive {
      display: none;
    }
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
      display: block;
    }
  
      /* Hide table headers (but not display: none;, for accessibility) */
      #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
  
    #no-more-tables tr {
      border: 1px solid #ccc;
      margin-top: 5px;
    }
  
    #no-more-tables td {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align: left;
    }
  
      #no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
      }
  
      /*
      Label the data
      */
      #no-more-tables td:before {
        content: attr(data-title);
      }
  }
  